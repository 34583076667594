import React from "react";
import styles from "./ComingSoon.module.scss";
import whiteLogo from "../../assets/svg/whiteLogo.svg";
import backgroundImage from "../../assets/GPBG.webp";

const ComingSoon = () => {
  return (
    <div
      className={styles.screen}
      style={{
        background: `url(${backgroundImage}) no-repeat center center / cover`,
      }}
    >
      <img src={whiteLogo} alt="Logo" />
      <div>
        <h1>Coming Soon</h1>
        <h2>WE ARE HIRING</h2>
      </div>
      <div>
        <h6>jobs@greenandprotein.de</h6>
        <h3>This photo contains something you may find exciting.</h3>
      </div>
    </div>
  );
};

export default ComingSoon;
