import React, { useEffect, useState } from "react";
import styles from "./Contact.module.scss";
import contactImg from "../../assets/contactImg.png";
import * as yup from "yup";
import { Formik, Form, Field } from "formik";
import Error from "../../Components/Error/Error";
import { useLanguage } from "../../context/Language";
import { getContactpage } from "../../utils/api";
import axios from "axios";

const Contact = () => {
  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const schema = yup.object({
    name: yup.string().required("enter your name"),
    email: yup.string().email().required("enter your email"),
    number: yup.string().matches(phoneRegExp).required("enter your number"),
    message: yup.string().required("write your message"),
  });

  let entryValues = { name: "", email: "", number: "", message: "" };

  async function checkEntries(values, {setSubmitting,resetForm}) {
    setSubmitting(true)
    let data = new FormData()
    data.append('name', values.name);
    data.append('email', values.email);
    data.append('number', values.number);
    data.append('message', values.message);
    
    let config = {
      method: 'post',
      maxBodyLength: Infinity,
      url: 'http://localhost:1337/api/email',
      data : data
    };
    
    axios.request(config)
    .then((response) => {
      window.open("/thank-you","_self")
    })
    .catch((error) => {
      setSubmitting(false)
      window.alert("Something went wrong! Try again later")
      console.log(error);
      resetForm()
    });
  }

  const [error,setError] = useState(false)
  const [textData,setTextData] = useState({})
  const {currentLanguage} = useLanguage()

  useEffect(() => {
    //fetch menu items
    const fetchData = async () => {
      try {
        const res = await getContactpage(currentLanguage);
        setTextData(res.data);
      } catch (err) {
        setError(true);
        console.log(err);
      }
    };

    if (currentLanguage) {
      fetchData();
    }
  }, [currentLanguage]);

  if (error) {
    return <Error />;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <div className={styles.content}>
          <h2>
            {currentLanguage === "en"?<>Get in <span>Touch</span></>:<><span>Schreiben</span> Sie Uns</>}
          </h2>
          <p>{textData.description}</p>
          <Formik
            validationSchema={schema}
            initialValues={entryValues}
            onSubmit={checkEntries}
          >
            {({ isSubmitting, touched, errors }) => (
              <Form>
                <Field
                  name="name"
                  id="name"
                  className={styles.input}
                  placeholder={textData.name}
                  style={{
                    borderColor: errors.name && touched.name ? "#ff0000" : "",
                  }}
                />
                <Field
                  name="email"
                  id="email"
                  className={styles.input}
                  placeholder={textData.email}
                  style={{
                    borderColor: errors.email && touched.email ? "#ff0000" : "",
                  }}
                />
                <Field
                  name="number"
                  id="number"
                  className={styles.input}
                  placeholder={textData.number}
                  style={{
                    borderColor:
                      errors.number && touched.number ? "#ff0000" : "",
                  }}
                />
                <Field
                  name="message"
                  id="message"
                  className={styles.input}
                  placeholder={textData.message}
                  style={{
                    height: "150px",
                    borderColor: errors.message && touched.message ? "#ff0000" : "",
                  }}
                  as="textarea"
                />
                <button type="submit" disabled={isSubmitting} style={{backgroundColor:isSubmitting?'#eb578f':'#DC2268',cursor:isSubmitting?'wait':'pointer'}}>
                {textData.sendButton}
                </button>
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <div className={styles.right}>
        <img src={contactImg} alt="" />
        <div className={styles.whiteBox} />
      </div>
    </div>
  );
};

export default Contact;
