import axios from "axios";

const axiosInstance = axios.create({
  //baseURL: "http://localhost:1337/api",
  baseURL: "https://green-and-protein-backend.onrender.com/api",
});

const checkLang = (lang) => {
  if (!lang) {
    throw new Error("Language parameter is required.");
  }
};

export const getHomepage = async (lang) => {
  checkLang(lang);

  const response = await axiosInstance.get(
    `/homepage?locale=${lang}&populate=*`
  );
  return response?.data ?? null;
};

export const getThankYouPage = async (lang) => {
  checkLang(lang);

  const response = await axiosInstance.get(
    `/thankyou?locale=${lang}&populate=*`
  );
  return response?.data ?? null;
};

export const getHeader = async (lang) => {
  checkLang(lang);

  const response = await axiosInstance.get(`/header?locale=${lang}&populate=*`);

  return response?.data ?? null;
};

export const getFooter = async (lang) => {
  checkLang(lang);

  const response = await axiosInstance.get(`/footer?locale=${lang}&populate=*`);
  return response?.data ?? null;
};

export const getRecipepage = async (lang) => {
  checkLang(lang);

  const response = await axiosInstance.get(
    `/recipepage?locale=${lang}&populate=*`
  );
  return response?.data ?? null;
};

export const getPositionpage = async (lang) => {
  checkLang(lang);

  const response = await axiosInstance.get(
    `/positionpage?locale=${lang}&populate=*`
  );
  return response?.data ?? null;
};

export const getRecipespage = async (lang) => {
  checkLang(lang);

  const response = await axiosInstance.get(
    `/recipespage?locale=${lang}&populate=*`
  );
  return response?.data ?? null;
};

export const getMenupage = async (lang) => {
  checkLang(lang);

  const response = await axiosInstance.get(
    `/menupage?locale=${lang}&populate=*`
  );
  return response?.data ?? null;
};

export const getCareerpage = async (lang) => {
  checkLang(lang);

  const response = await axiosInstance.get(
    `/careerpage?locale=${lang}&populate=*`
  );
  return response?.data ?? null;
};

export const getContactpage = async (lang) => {
  checkLang(lang);

  const response = await axiosInstance.get(
    `/contact-page?locale=${lang}&populate=*`
  );
  return response?.data ?? null;
};

export const getAboutpage = async (lang) => {
  checkLang(lang);

  const response = await axiosInstance.get(
    `/aboutpage?locale=${lang}&populate=deep`
  );
  return response?.data ?? null;
};

export const getReviews = async (lang) => {
  const response = await axiosInstance.get(
    `/reviews?locale=${lang}&populate=*`
  );
  return response?.data ?? null;
};

export const getBlogposts = async (lang) => {
  const response = await axiosInstance.get(
    `/blogs?locale=${lang}&populate=deep`
  );
  return response?.data ?? null;
};

export const getBlogpost = async (lang, id) => {
  const response = await axiosInstance.get(
    `/blogs/${id}?locale=${lang}&populate=deep`
  );
  return response?.data ?? null;
};

export const getRecipes = async (lang) => {
  const response = await axiosInstance.get(
    `/recipes?locale=${lang}&populate=*`
  );
  return response?.data ?? null;
};

export const getJobpositions = async (lang) => {
  const response = await axiosInstance.get(`/job-positions?locale=${lang}`);
  return response?.data ?? null;
};

export const getJobposition = async (lang, jobTitle) => {
  const response = await axiosInstance.get(
    `/job-positions?locale=${lang}&populate=deep&filters[jobTitle][$eq]=${jobTitle}`
  );
  return response?.data ?? null;
};

export const getFAQ = async (lang) => {
  const response = await axiosInstance.get(`/faqs?locale=${lang}`);
  return response?.data ?? null;
};

export const getRecipe = async (id) => {
  const response = await axiosInstance.get(
    `/recipes/${id}?populate=deep`
  );
  return response?.data ?? null;
};

export const getMenuItems = async (lang, page = 1, query = "") => {
  const response = await axiosInstance.get(
    `/menu-items?locale=${lang}&populate=*&pagination[page]=${page}&pagination[pageSize]=1000${query}`
  );
  return response?.data ?? null;
};
export const getMenuItemById = async (id, lang) => {
  const response = await axiosInstance.get(
    `/menu-items/${id}?populate=*`
  );
  return response?.data ?? null;
};
export const getMenuCategories = async (lang) => {
  const response = await axiosInstance.get(
    `/menu-categories?locale=${lang}&populate=*,menu_items`
  );
  return response?.data ?? null;
};

export const getMenuTypes = async (lang) => {
  const response = await axiosInstance.get(
    `/food-types?locale=${lang}&populate=*,menu_items`
  );
  return response?.data ?? null;
};
